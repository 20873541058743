export default {
  light: {
    primary: "#536dfe",
    primaryConst: "#263275",
    secondary: "#FF5C93",
    secondaryConst: "#FF5C93",
    success: "#3CD4A0",
    successConst: "#3CD4A0",
    error: "#FF4081",
    warning: "#FFC260",
    info: "#9013FE",
    background: "#4a4a4a",
    backgroundConst: "#303030"
  },
  dark: {
    primary: "#232323",
    primaryConst: "#FFF5A0",
    secondary: "#fcc13b",
    secondaryConst: "#000",
    success: "#388e3c",
    successConst: "#3CD4A0",
    error: "#fc2929",
    warning: "#FFC260",
    info: "#9013FE",
    background: "#1e1e1e",
    backgroundConst: "#4a4a4a",
  },
};


