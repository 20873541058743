export default {
    namespaced: true,
    state: {
        adminData: null,
    },

    getters: {
        admin: state => state.adminData,
    },

    mutations: {
        setAdminData(state, admin) {
            state.adminData = admin;
        }
    },

    actions: {},

    modules: {}
}
