import Vue from 'vue'
import Vuex from 'vuex'
import admin from "./admin";
import errors from "./errors";
import notifications from "./notifications";
import SystemTimer from "./system_timer";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        admin,
        errors,
        notifications,
        SystemTimer,
    }
});
