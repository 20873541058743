import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        beforeEnter: (to, from, next) => {
            if (!window.localStorage.getItem("adminToken")) {
                return next();
            } else {
                return next({name: 'Dashboard', query: {to: to?.name ?? ""}});
            }
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Login/Login'),
    },
    {
        path: '/admin/ms-auth/callback',
        name: 'ms-login-callback',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Login/MsLoginCallback'),
    },

    {
        path: '/',
        component: () => import(/* webpackChunkName: "layout" */ '@/components/Layout/Layout'),
        beforeEnter: (to, from, next) => {
            if (window.localStorage.getItem("adminToken")) {
                return next();
            } else {
                return next({name: 'Login', query: {to: to?.name ?? ""}});
            }
        },
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard/Dashboard'),
            },

            /* ---------- Clients ---------- */
            {
                path: 'clients/:id',
                props: true,
                redirect: 'clients/:id/account',
                component: () => import(/* webpackChunkName: "client" */ '@/pages/Client/Client'),
                children: [
                    {
                        path: 'account',
                        name: 'Client',
                        component: () => import(/* webpackChunkName: "client.account" */ '@/pages/Client/ClientAccount'),
                    },
                    {
                        path: 'profile',
                        name: 'Client Profile',
                        component: () => import(/* webpackChunkName: "client.profile" */ '@/pages/Client/ClientProfile'),
                    },
                    {
                        path: 'companies',
                        name: 'Client Companies',
                        component: () => import(/* webpackChunkName: "Client.companies" */ '@/pages/Client/ClientCompanies'),
                    },
                    {
                        path: 'applications',
                        name: 'Client Applications',
                        component: () => import(/* webpackChunkName: "client.applications" */ '@/pages/Client/ClientInvestments'),
                    },
                    {
                        path: 'investments',
                        name: 'Client Investments',
                        component: () => import(/* webpackChunkName: "client.investments" */ '@/pages/Client/ClientInvestments'),
                    },
                    {
                        path: 'debt-funds',
                        name: 'Client Debt Funds',
                        component: () => import(/* webpackChunkName: "contract.list" */ '@/pages/NewDebtFund/NewDebtFundsPerformance'),
                        props: {for_entity: 'client'}
                    },
                    {
                        path: 'debentures',
                        name: 'Client Debenture',
                        component: () => import(/* webpackChunkName: "debenture.list" */ '@/pages/Debenture/DebentureList'),
                        props: {debenture_for: 'client'}
                    },
                    {
                        path: 'documents',
                        name: 'Client Documents',
                        component: () => import(/* webpackChunkName: "client.documents" */ '@/pages/Client/ClientDocuments'),
                    },
                    {
                        path: 'certifications',
                        name: 'AML / KYC',
                        component: () => import(/* webpackChunkName: "client.certifications" */ '@/pages/Client/ClientCertifications/ClientCertifications'),
                    },
                    {
                        path: 'logs',
                        name: 'Client Event Logs',
                        component: () => import(/* webpackChunkName: "client.logs" */ '@/components/Logs/LogsComponent'),
                        props: route => ({entity: 'client', type: 'logs', ...route.query, ...route.params}),
                    },
                    {
                        path: 'activities',
                        name: 'Client Activities',
                        component: () => import(/* webpackChunkName: "client.activities" */ '@/components/Logs/LogsComponent'),
                        props: route => ({owner: 'client', type: 'events', ...route.query, ...route.params}),
                    },
                    {
                        path: 'bank-account',
                        name: "Client Bank Account",
                        component: () => import(/* webpackChunkName: "client.bank-account" */ '@/components/Common/BankAccountComponent'),
                        props: route => ({entity: 'client', ...route.query, ...route.params}),
                    },
                ]
            },
            {
                path: '/clients',
                name: 'Client List',
                component: () => import(/* webpackChunkName: "client.list" */ '@/pages/Client/ClientList'),
            },
            {
                path: '/client/add',
                name: 'New Client',
                component: () => import(/* webpackChunkName: "client.add" */ '@/pages/Client/ClientAdd'),
            },

            /* ---------- Companies ---------- */
            {
                path: 'companies',
                name: 'Company List',
                component: () => import(/* webpackChunkName: "companies" */ '@/pages/Companies/Companies'),
            },
            {
                path: 'company/add',
                name: 'New Company',
                component: () => import(/* webpackChunkName: "company.add" */ '@/pages/Companies/CompanyAdd'),
            },
            {
                path: 'companies/:id',
                props: true,
                redirect: 'clients/:id/account',
                component: () => import(/* webpackChunkName: "company" */ '@/pages/Companies/Company'),
                children: [
                    {
                        path: 'profile',
                        name: 'Company Profile',
                        component: () => import(/* webpackChunkName: "company.profile" */ '@/pages/Companies/CompanyProfile'),
                    },
                    {
                        path: 'investments',
                        name: 'Company Investments',
                        component: () => import(/* webpackChunkName: "company.investments" */ '@/pages/Companies/CompanyInvestments'),
                    },
                    {
                        path: 'debentures',
                        name: 'Company Debenture',
                        component: () => import(/* webpackChunkName: "debenture.list" */ '@/pages/Debenture/DebentureList'),
                        props: {debenture_for: 'company'}
                    },
                    {
                        path: 'debt-funds',
                        name: 'Company Debt Funds',
                        component: () => import(/* webpackChunkName: "contract.list" */ '@/pages/NewDebtFund/NewDebtFundsPerformance'),
                        props: {for_entity: 'company'}
                    },
                    {
                        path: 'documents',
                        name: 'Company Documents',
                        component: () => import(/* webpackChunkName: "client.documents" */ '@/pages/Companies/CompanyDocuments'),
                    },
                    {
                        path: 'logs',
                        name: 'Company Event Logs',
                        component: () => import(/* webpackChunkName: "company.logs" */ '@/components/Logs/LogsComponent'),
                        props: route => ({entity: 'company', type: 'logs', ...route.query, ...route.params}),
                    },
                    {
                        path: 'bank-account',
                        name: "Company Bank Account",
                        component: () => import(/* webpackChunkName: "company.bank-account" */ '@/components/Common/BankAccountComponent'),
                        props: route => ({entity: 'company', ...route.query, ...route.params}),
                    },
                ]
            },

            /* ---------- Portfolios ---------- */
            {
                path: 'portfolios',
                name: 'Portfolio Company List',
                component: () => import(/* webpackChunkName: "portfolio.list" */ '@/pages/Portfolio/PortfolioList'),
            },
            {
                path: 'portfolio/add',
                name: 'New Portfolio',
                component: () => import(/* webpackChunkName: "portfolio.add" */ '@/pages/Portfolio/PortfolioAdd'),
            },
            {
                path: 'portfolios/:id',
                props: true,
                redirect: 'portfolios/:id/details',
                component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/Portfolio/Portfolio'),
                children: [
                    {
                        path: 'details',
                        name: 'Portfolio Company',
                        component: () => import(/* webpackChunkName: "portfolio.details" */ '@/pages/Portfolio/PortfolioDetails'),
                    },
                    {
                        path: 'documents',
                        name: "Portfolio Company's Documents",
                        component: () => import(/* webpackChunkName: "portfolio.documents" */ '@/pages/Portfolio/PortfolioDocuments'),
                    },
                    {
                        path: 'opportunities',
                        name: "Portfolio Company's Opportunities",
                        component: () => import(/* webpackChunkName: "portfolio.opportunities" */ '@/pages/Portfolio/PortfolioOpportunities'),
                    },
                    {
                        path: 'valuations',
                        name: "Portfolio Company's Valuations",
                        component: () => import(/* webpackChunkName: "portfolio.valuations" */ '@/pages/Portfolio/PortfolioValuations'),
                    },
                    {
                        path: 'capital-raises',
                        name: "Portfolio Company's Capital Raises",
                        component: () => import(/* webpackChunkName: "portfolio.investments" */ '@/pages/Portfolio/PortfolioCapitalRaises'),
                    },
                    {
                        path: 'bank',
                        name: "Portfolio Company's Bank",
                        component: () => import(/* webpackChunkName: "portfolio.bank" */ '@/pages/Portfolio/PortfolioBank'),
                    },
                    {
                        path: 'contact',
                        name: 'Portfolio Contact',
                        component: () => import(/* webpackChunkName: "portfolio.contact" */ '@/pages/Portfolio/PortfolioContact'),
                    },
                    {
                        path: 'logs',
                        name: 'Portfolio Event Logs',
                        component: () => import(/* webpackChunkName: "portfolio.logs" */ '@/components/Logs/LogsComponent'),
                        props: route => ({entity: 'portfolio', type: 'logs', ...route.query, ...route.params}),
                    },
                ]
            },

            /* ---------- Valuations ---------- */
            {
                path: 'valuations',
                name: 'Valuation List',
                component: () => import(/* webpackChunkName: "valuation.list" */ '@/pages/Valuation/ValuationList'),
            },
            {
                path: 'valuation/add',
                name: 'New Valuation',
                component: () => import(/* webpackChunkName: "valuation.add" */ '@/pages/Valuation/ValuationAdd'),
            },

            /* ---------- Capital Raises ---------- */
            {
                path: 'capital-raises',
                name: 'Capital Raise List',
                component: () => import(/* webpackChunkName: "capitalRaise.list" */ '@/pages/CapitalRaise/CapitalRaiseList'),
            },
            {
                path: 'capital-raises/:id?/add',
                name: 'New Capital Raise',
                props: true,
                component: () => import(/* webpackChunkName: "capitalRaise.add" */ '@/pages/CapitalRaise/Add/CapitalRaiseAdd'),
            },
            {
                path: 'capital-raises/:id',
                props: true,
                name: 'Capital Raise',
                component: () => import(/* webpackChunkName: "capitalRaise.edit" */ '@/pages/CapitalRaise/CapitalRaiseEdit'),
            },
            // {
            //     path: 'investments',
            //     name: 'Investment Vehicles',
            //     component: () => import(/* webpackChunkName: "investment.list" */ '@/pages/CapitalRaise/InvestmentList'),
            // },

            /* ---------- Opportunities ---------- */
            {
                path: 'opportunities',
                name: 'Opportunity List',
                component: () => import(/* webpackChunkName: "opportunity.list" */ '@/pages/Opportunity/OpportunityList'),
            },

            /* ---------- Debentures ---------- */
            {
                path: 'debentures',
                name: 'Debenture List',
                component: () => import(/* webpackChunkName: "debenture.list" */ '@/pages/Debenture/DebentureList'),
            },
            {
                path: 'debenture/add',
                name: 'New Debenture',
                component: () => import(/* webpackChunkName: "debenture.add" */ '@/pages/Debenture/DebentureAdd'),
            },

            /* ---------- Debt Funds ---------- */
            {
                path: 'contracts',
                name: 'Debt Fund Contract List',
                component: () => import(/* webpackChunkName: "contract.list" */ '@/pages/Debt/ContractList.vue'),
                props: {contracts_for: 'all'}
            },
            {
                path: 'contract/add',
                name: 'New Debt Fund Contract',
                component: () => import(/* webpackChunkName: "contract.add" */ '@/pages/Debt/ContractAdd.vue'),
            },
            {
                path: 'contract/:id',
                props: true,
                name: 'Debt Fund Contract Edit',
                component: () => import(/* webpackChunkName: "debt.edit" */ '@/pages/Debt/ContractEdit.vue'),
            },
            {
                path: 'debt-funds/settings',
                props: true,
                name: 'Debt Fund Settings',
                component: () => import(/* webpackChunkName: "debt.settings" */ '@/pages/Debt/DebtFundSettings.vue'),
            },

            /* ---------- New Debt Funds ---------- */
            {
                path: 'debt-funds/management',
                name: 'Debt Fund Management',
                component: () => import(/* webpackChunkName: "debt_funds.management" */ '@/pages/NewDebtFund/DebtFundManagement.vue'),
                props: {contracts_for: 'all'}
            },
            {
                path: 'debt-funds/performance',
                name: 'Debt Fund Performance',
                component: () => import(/* webpackChunkName: "debt_funds.performance" */ '@/pages/NewDebtFund/NewDebtFundsPerformance.vue'),
            },
            {
                path: 'debt-funds/reconciliation',
                name: 'Debt Fund Reconciliation',
                component: () => import(/* webpackChunkName: "debt_funds.reconciliation" */ '@/pages/NewDebtFund/DebtFundsReconciliation.vue'),
            },

            /* ---------- Report ---------- */
            {
                path: 'reports/spv',
                component: () => import(/* webpackChunkName: "report.spv" */ '@/pages/Report/ReportSPV/ReportSPV'),
                children: [
                    {
                        path: ':id',
                        props: true,
                        name: 'SPV Report',
                        component: () => import(/* webpackChunkName: "reports.spv.details" */ '@/pages/Report/ReportSPV/ReportSPVDetails'),
                    },
                ]
            },
            {
                path: 'reports/client',
                component: () => import(/* webpackChunkName: "report.client" */ '@/pages/Report/ReportClient/ReportClient'),
                children: [
                    {
                        path: ':id',
                        props: true,
                        name: 'Client Report',
                        component: () => import(/* webpackChunkName: "reports.client.details" */ '@/pages/Report/ReportClient/ReportClientDetails'),
                    },
                ]
            },

            /* ---------- Broadcast ---------- */
            {
                path: '/broadcast',
                name: 'BroadcastList',
                component: () => import(/* webpackChunkName: "files" */ '@/pages/Broadcast/BroadcastList'),
            },

            /* ---------- File Browser ---------- */
            {
                path: '/files',
                name: 'Browser',
                component: () => import(/* webpackChunkName: "files" */ '@/pages/Browser/Browser'),
            },

            /* ---------- Notes ---------- */
            {
                path: 'notes',
                name: 'Notes',
                component: () => import(/* webpackChunkName: "notes" */ '@/pages/Notes/Notes'),
            },

            /* ---------- Tickets ---------- */
            {
                path: 'tickets/:id',
                name: 'Ticket',
                component: () => import(/* webpackChunkName: "ticket" */ '@/pages/Tickets/Ticket'),
            },
            {
                path: 'tickets',
                name: 'Tickets',
                component: () => import(/* webpackChunkName: "tickets" */ '@/pages/Tickets/Tickets'),
            },

            /* ---------- Settings ---------- */
            {
                path: '/settings',
                name: 'Settings',
                redirect: 'settings/certifications',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/Settings/Settings'),
                children: [
                    {
                        path: 'certifications',
                        component: () => import(/* webpackChunkName: "settings.certifications" */ '@/pages/Settings/Certifications'),
                    },
                    {
                        path: 'email-templates',
                        component: () => import(/* webpackChunkName: "settings.certifications" */ '@/pages/Settings/EmailTemplates'),
                    },
                ]
            },

            /* ---------- Admins ---------- */
            {
                path: 'admins/:id',
                props: true,
                redirect: 'admins/:id/profile',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/Admin/Admin'),
                children: [
                    {
                        path: 'profile',
                        name: 'Admin Profile',
                        component: () => import(/* webpackChunkName: "admin.profile" */ '@/pages/Admin/AdminProfile'),
                    },
                    {
                        path: 'change-password',
                        name: 'Admin Change Password',
                        component: () => import(/* webpackChunkName: "admin.change.password" */ '@/pages/Admin/AdminChangePassword'),
                    },
                    {
                        path: 'logs',
                        name: 'Admin Event Logs',
                        component: () => import(/* webpackChunkName: "admin.logs" */ '@/components/Logs/LogsComponent'),
                        props: route => ({entity: 'admin', type: 'logs', ...route.query, ...route.params}),
                    },
                    {
                        path: 'activities',
                        name: 'Admin Activities',
                        component: () => import(/* webpackChunkName: "admin.activities" */ '@/components/Logs/LogsComponent'),
                        props: route => ({owner: 'admin', type: 'events', ...route.query, ...route.params}),
                    },
                ]
            },

            {
                path: '/admins',
                name: 'Admins',
                component: () => import(/* webpackChunkName: "admin.list" */ '@/pages/Admin/Admins'),
            },
            {
                path: '/admin/add',
                name: 'New Admin',
                component: () => import(/* webpackChunkName: "admin.add" */ '@/pages/Admin/AdminAdd'),
            },

        ]
    },
    {
        path: "*",
        name: 'Not Found',
        component: () => import(/* webpackChunkName: "404" */ '@/pages/NotFound/NotFound'),
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router;
