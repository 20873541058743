export default {
    namespaced: true,
    state: {
        timer: 0,
    },
    getters: {
        timer: state => state.timer,
    },
    mutations: {
        setTimer(state, timer) {
            state.timer = timer;
        },
        countDown(state) {
            state.timer--;
        },
    },
    actions: {},
    modules: {}
}
