import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './routes';
import store from './store/index';
import VCurrencyField from 'v-currency-field'
import {VTextField} from 'vuetify/lib'
import VueApexCharts from 'vue-apexcharts'
import dayjs from "dayjs";

Vue.config.productionTip = false;

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    locale: 'en',
    decimalLength: 0,
    autoDecimalMode: false,
    min: 0,
    max: null,
    defaultValue: null,
    valueAsInteger: false,
    allowNegative: false
})

Vue.use(VueApexCharts)
Vue.component('apexchartComponent', VueApexCharts)

String.prototype.toTitle = function () {
    return this.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
    });
}

String.prototype.truncate = String.prototype.truncate ||
    function (n, useWordBoundary) {
        if (this.length <= n) {
            return this;
        }
        const subString = this.slice(0, n - 1); // the original check
        return (useWordBoundary
            ? subString.slice(0, subString.lastIndexOf(" "))
            : subString) + "...";
    };

Date.prototype.customFormat = function (format) {
    // YYYY-MM-DD HH:mm:ss
    return dayjs(this).format(format);
}

Date.prototype.customDateFormat = function (format = "DD MMM YYYY") {
    // YYYY-MM-DD HH:mm:ss
    return this.customFormat(format);
}

Date.prototype.customDateTimeFormat = function (format = "DD MMM YYYY HH:mm:ss") {
    // YYYY-MM-DD HH:mm:ss
    return this.customFormat(format);
}

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
